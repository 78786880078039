<template>
  <div>
    <b-row>
      <b-col cols="9">
        <p class="h4 mb-0">
          {{ item.name }}
        </p>
        <p v-if="item.suggestedQuantity" class="h6 text-muted mb-0">
          <span>Obligatoire&nbsp;: {{ requiredString }}</span>
          <br />
          <span>Quantité que l'étudiant doit se procurer&nbsp;: {{ item.suggestedQuantity }}</span>
        </p>
      </b-col>
    </b-row>
    <ItemProducts
      :category="category"
      :item-id="item.id"
      :readonly="readonly"
    />
  </div>
</template>
<script>
import ItemProducts from '@/components/SchoolLists/Items/ItemProducts'

export default {
  components: {
    ItemProducts,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
    requiredString() {
      return this.item.required ? 'oui' : 'non'
    },
  },
}
</script>
